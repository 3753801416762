.mpd-seating__app.context-viewer {
  min-height: calc(80vh - 152px);
}

.mpd-seating__app.context-viewer .mpd-seating__viewer-sidebar__instruction {
  padding: 15px;
  margin-bottom: 10px;
  text-align: center;
}

@media (max-width: 881px) {
  .mpd-seating__app.context-viewer .mpd-seating__viewer-sidebar__instruction {
    padding: 3px 15px;
    margin-bottom: 3px;
    font-size: 0.8em;
  }

  .mpd-seating__app.context-viewer .event-date-admin-sidebar__category-header {
    padding: 6px 15px;
    font-size: 0.9em;
  }

  .mpd-seating__app.context-viewer .seat-config-caption {
    margin-top: 4px;
  }
}
