#demo,
.seating-demo-root-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.seating-demo-header {
  padding: 5px;
  width: 100%;
  color: #cdcdcd;
  display: flex;
  justify-content: space-between;
  background: #424242;
}

.seating-demo-header--impersonnated {
  background: #bf3f34;
}

.seating-demo-root {
  padding: 20px;
  flex-grow: 1;
}

.seating-demo-root,
.seating-demo-root__content,
.seating-demo-root__tabContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.seating-demo__wrapper {
  height: 100vh;
}

.mpd-block.mpd-block-has-tabs > .mpd-block-tabs {
  height: auto;
}

.mpd-block.mpd-block-has-tabs .mpd-block-tab {
  height: auto;
}

@media (max-width: 768px) {
  .mpd-block.mpd-block-has-tabs .mpd-block-tab {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }
}

.seating-demo__loaderCentered {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 250px;
  height: 180px;
  text-align: center;
}

.seating-demo-root__tabContent > .mpd-seating__app {
  flex-grow: 1;
}

.seating-demo__flex-container-centered {
  display: flex;
  align-items: center;
}

.seating-demo__flex-container-centered-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mpd-popover__link.active {
  background: #d2d7dc;
}
