@import '../../../node_modules/@mapado/makeup/src/styles/base/_default_variables.scss';
@import '../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.mpd-seating-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: $sidebarWidth;
  min-width: $sidebarWidth;
  background-color: #ffffff;
  box-shadow: -5px 0 11px 0 rgba(1, 30, 64, 0.1);
  position: relative;
  margin-left: 5px;
  flex: 0 0 $sidebarWidth;
  z-index: 3;
}

.mpd-seating-tabbed-sidebar {
  max-width: fit-content;
  display: flex;
  flex-direction: column;
}

.mpd-seating-sidebar__close-seating-spacing {
  margin-bottom: 60px;
}

.mpd-seating-sidebar__scrollable {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.mpd-seating-sidebar__scrollable--with-close {
  height: calc(100% - 60px);
}

.mpd-seating-sidebar__close-seating-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  width: 84px;
  background-color: #001c3c;
  color: #fff;
  border: none;
  font-size: 10px;
  padding: 6px;
  position: absolute;
  top: 0;
  right: 0;
}

.mpd-seating-sidebar__close-seating-btn:focus,
.mpd-seating-sidebar__close-seating-btn:hover,
.mpd-seating-sidebar__close-seating-btn:active {
  cursor: pointer;
}

.mpd-seating-sidebar-header {
  text-align: center;
  padding: 9px 14px;
}

.mpd-seating-sidebar-header__title {
  padding-top: 20px;
  margin-bottom: 5px;
}

.mpd-seating-sidebar-header__help {
  font-size: 12px;
}

.mpd-seating-sidebar-tabs {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px $secondaryLightGray;

  & > div {
    text-align: center;
    flex: 1;
  }
}
