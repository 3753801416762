@import '../../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.seatGroup-selector {
  flex-direction: row-reverse;
  justify-content: left;
  padding: 15px;
}

.seatGroup-selector__container {
  display: inline-flex;
  flex-direction: column;
  min-width: 240px;
}

.seatGroup-selector__row {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #fff;

  transition: 0.2s ease-in-out background-color;

  &:hover {
    background-color: $secondaryLightGray;
    font-weight: bold;
  }
}
