@import '../../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.mpd-seating-sidebar-card {
  position: relative;
  top: 0;
  background-color: $default-white;
  text-align: left;
  cursor: pointer;

  &.selected {
    color: $default-white;
    background-color: $primaryOrange;
  }
}

.mpd-seating-sidebar-message-card {
  padding: 20px;
  background-color: $secondaryWhite !important;
}
