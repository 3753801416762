@import '../../../../node_modules/@mapado/makeup/src/styles/base/_default_variables.scss';
@import '../../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.stock-management-sidebar {
  display: flex;
  flex-direction: column;

  &__content {
    h3,
    p {
      text-align: center;
    }
  }

  &__item {
    margin-left: 10px;

    .editable-stock-contingent-row__title {
      margin-left: 5px;
    }
  }

  &__actionButton {
    padding: 0;

    .mpd-btn {
      width: 100% !important;
    }
  }

  &__help {
    margin: 15px;
    padding: 15px;
    background-color: $secondaryWhite;
    border-radius: 10px;
  }
}

.stock-management-sidebar__content .editable-stock-contingent-row:first-child {
  border: none;
}

.stock-management-sidebar__content .editable-stock-contingent-row:last-child {
  border-bottom: solid 1px $secondaryLightGray;
}
