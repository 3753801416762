@import '../../../../node_modules/@mapado/makeup/src/styles/base/_default_variables.scss';
@import '../../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

// TODO Delete once StockManagement is in prod
.stock-contingent-sidebar {
  display: flex;
  flex-direction: column;
}

.stock-contingent-content {
  h3,
  p {
    text-align: center;
  }

  &__item {
    margin-left: 10px;

    .editable-stock-contingent-row__title {
      margin-left: 5px;
    }
  }
}

.stock-contingent-content .editable-stock-contingent-row:first-child {
  border: none;
}

.stock-contingent-content .editable-stock-contingent-row:last-child {
  border-bottom: solid 1px $secondaryLightGray;
}

.stock-contingent-content .seat-group-row:first-child {
  border: none;
}

.stock-contingent-content .seat-group-row:last-child {
  border-bottom: solid 1px $secondaryLightGray;
}

.stock-contingent-button {
  padding: 0;

  .mpd-btn {
    width: 100% !important;
  }
}
