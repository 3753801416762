.mpd-seating__config__block rect,
.mpd-seating__config__block circle,
.mpd-seating__config__block line {
  border-radius: 50%;
}

.mpd-seating__config__block text {
  fill: #424242;
  font-family: Circular, Helvetica, arial, sans-serif;
  text-anchor: middle;
  text-transform: uppercase;
  letter-spacing: 5px;
}
