@import '../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.mpd-seating__seat {
  cursor: default;
  z-index: 1;
  position: relative;
}

.mpd-seating__seat.mpd-seating__seat--is-selectable:hover,
.mpd-seating__seat.mpd-seating__seat--is-selectable:focus,
.mpd-seating__seat.mpd-seating__seat--is-selectable:active {
  cursor: pointer;
}

.mpd-seating__seat:not(.mpd-seating__seat--is-selectable),
.mpd-seating__seat.mpd-seating__seat--is-moving-seat:not(.mpd-seating__seat--is-selectable)
  > .mpd-icon {
  fill-opacity: 0.3;
}

.mpd-seating__seat.mpd-seating__seat--is-selected .mpd-icon,
.mpd-seating__seat.mpd-seating__seat--is-focused .mpd-icon {
  fill: $primaryOrange;
}
