@import '../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.seat-group-row {
  margin: 8px 6px;
  border-top: solid 1px $secondaryLightGray;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:before {
      content: '';
      display: block;
      height: 26px;
      width: 26px;
    }
  }

  &__icon-label {
    margin-right: $margin-large;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    padding: $padding-larger;
    align-items: center;
    padding-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__counts {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: $padding-large;
  }

  &__count {
    color: $secondaryGray;

    b {
      color: $primaryBlack;
    }
  }

  &__form {
    padding: $padding-large;
    padding-bottom: 0;

    label {
      font-size: 12px;
      white-space: nowrap;
    }

    input[type='color'] {
      height: 41px;
      padding: 0;
    }
  }

  &--selectable {
    cursor: pointer;

    .seat-group-row__counts {
      padding: $padding-larger;
    }
  }

  &--selected {
    border: solid 1px $primaryOrange !important;
    border-radius: 3px;
    margin: 8px 6px;

    .seat-group-row__counts {
      padding: $padding-larger;
    }
  }
}

.seat-group-row--selected + .seat-group-row--selectable {
  border-top: none;
}

.stock-contingent-content .seat-group-row:first-child {
  border: none;
}

.stock-contingent-content .seat-group-row-row:last-child {
  border-bottom: solid 1px $secondaryLightGray;
}
