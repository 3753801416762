@import '../../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.booking-collection {
  flex: 1;
  text-align: center;

  &__detail {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    padding: 20px 0 0;

    &__header {
      padding: 0 20px;
    }
  }

  &__description {
    background-color: $secondaryWhite;
    font-size: $font-size-small;
    border-radius: 10px;
    padding: $padding-larger;
    margin: 15px;
    text-align: center;
  }

  &__list {
    margin: 20px 0;

    &__title {
      margin-bottom: 20px;
    }
  }

  ul {
    padding-left: 0;
  }
}
