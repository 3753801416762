@import '../../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.booking-line {
  padding: 0 20px;
}

.booking-line--clickable {
  cursor: pointer;
  padding: 20px 0;
  border-top: solid 1px $secondaryLightGray;

  &:focus,
  &:hover,
  &:active {
    background-color: $secondaryLightGray;
  }
}
