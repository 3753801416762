@import '../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.zoom-button {
  &:hover,
  &:active {
    background-color: $primaryOrange;
    color: white;
  }
}

.zoom-button--zoomIn:hover,
.zoom-button--zoomIn:active {
  cursor: zoom-in;
}

.zoom-button--zoomOut:hover,
.zoom-button--zoomOut:active {
  cursor: zoom-out;
}
