@import '../../../node_modules/@mapado/makeup/src/styles/base/_breakpoints.scss';
@import '../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.plan-purchase-sidebar {
  display: flex;
  flex-direction: column;

  .ticket-price {
    justify-content: space-between;
  }
}

.plan-purchase-sidebar--force-visible {
  display: flex !important;
}

.plan-purchase-sidebar__ticket-prices {
  padding: 20px;
}

.plan-purchase-sidebar__seat-infos {
  display: flex;
  gap: $margin-small;

  span:first-child {
    font-weight: bold;
    max-width: 55%;
  }

  span:nth-child(2)::before {
    content: '|';
    margin-right: $margin-small;
  }
}

.plan-purchase-responsive-bar {
  display: none;
}

.plan-purchase-sidebar__actions {
  display: flex;
  flex-direction: column;
}

.plan-purchase-sidebar__actions,
.plan-purchase-responsive-bar {
  > .mpd-btn {
    margin: 0 !important;
  }
}

@media screen and (max-width: $breakpoint-to-medium) {
  .mpd-seating__app--plan-purchase {
    .mpd-seating__app__container {
      flex-direction: column;
    }
  }

  .mpd-seating__app__event-svg-wrapper {
    max-width: none;
  }

  .plan-purchase-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    width: auto;
    margin: 0;
  }

  .plan-purchase-sidebar__actions {
    flex-direction: row;
  }

  .plan-purchase-sidebar__actions,
  .plan-purchase-responsive-bar {
    > .mpd-btn {
      flex: 1;
    }
  }

  .plan-purchase-responsive-bar {
    display: flex;
  }
}
