@import '../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.seatgroup-sidebar {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid $secondaryWhite;
  z-index: 1;
  overflow-y: auto;
}

.seatgroup-sidebar__item {
  padding: 5px 10px;
  white-space: nowrap;

  &:not(:last-child) {
    border-right: 1px solid $secondaryWhite;
  }
}
