.mpd-seating--no-select {
  user-select: none;
}

.mpd-seating__editor__sidebar__seat-config-blocks {
  padding: 15px;
}

.mpd-seating__editor__sidebar .mpd-btn + .mpd-btn {
  margin-left: 0;
  margin-top: 10px;
  width: 100%;
}

.mpd-seating__editor-element:hover rect,
rect.mpd-seating__editor-element--selected {
  stroke: red;
}

.mpd-seating__editor-element:hover circle,
circle.mpd-seating__editor-element--selected {
  stroke: red;
}

.mpd-seating__editor-element:hover line,
line.mpd-seating__editor-element--selected {
  stroke: red;
}

.mpd-seating__editor-element:hover text,
text.mpd-seating__editor-element--selected {
  fill: red;
}

.mpd-seating__editor__tag {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.mpd-seating__editor__tag .mpd-btn + .mpd-btn {
  margin-left: 0;
  margin-top: 10px;
}

.mpd-seating__editor__property-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
}

.mpd-seating__editor__property-form {
  margin: 8px 0px;
  height: 65px;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.mpd-seating__editor__property-form label {
  font-size: 11px;
  margin-right: 0;
}

.mpd-seating__editor__property-form input {
  padding-left: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

.mpd-seating__editor__property-label {
  font-size: 10px !important;
  height: auto !important;
}

.mpd-seating__editor__html-code {
  padding: 6px;
}

.mpd-seating__editor__input {
  width: 100px;
}

.mpd-seating__editor__input--number {
  width: 120px;
}

.mpd-seating__editor__tag .mpd-seating__editor__property-list {
  opacity: 0.3;
  transition: opacity 0.2s ease;
}

.mpd-seating__editor__tag:hover .mpd-seating__editor__property-list {
  opacity: 1;
}

.mpd-seating__editor__property-list--disabled {
  opacity: 0.3 !important;
  pointer-events: none;
  transition: none !important;
}

.mpd-seating__editor__move-anchor {
  cursor: move;
  border: 1px solid #111;
  display: block;
  padding: 5px;
  text-align: center;
  color: #111;
}

.mpd-seating__editor__move-anchor:hover {
  text-decoration: none;
  color: #111;
}
