.mpd-seating__app.context-reservation .mpd-seating__order-sidebar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.mpd-seating__app.context-reservation .mpd-seating__order-sidebar__top {
  width: 100%;
}

.mpd-seating__order-sidebar__bigText {
  font-size: 40px !important;
  padding-top: 5px;
  padding-bottom: 10px;
  text-align: center;
}

.mpd-seating__order-sidebar__txtCenter {
  text-align: center;
}
