.mpd-seating__app.context-order .mpd-seating__order-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mpd-seating__app.context-order .mpd-seating__order-sidebar__top {
  width: 100%;
}

.mpd-seating__order-sidebar__bigText {
  font-size: 40px !important;
  padding-top: 5px;
  padding-bottom: 10px;
  text-align: center;
}

.mpd-seating__order-sidebar__txtCenter {
  text-align: center;
}

@media (max-width: 881px) {
  .mpd-seating__app.context-order .mpd-seating__order-sidebar__top {
    width: auto;
  }

  .mpd-seating__app.context-order .mpd-seating__order-sidebar {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .mpd-seating__app.context-order .mpd-seating__order-sidebar > * {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 768px) {
  .mpd-seating__order-sidebar > * {
    width: 50%;
  }
}
