@import '../../../node_modules/@mapado/makeup/src/styles/base/_breakpoints.scss';

.mpd-seating {
  position: relative;
  display: block;
}

.mpd-seating__app {
  position: relative;
  display: flex;
  pointer-events: all;
  height: 100%;
}

.mpd-seating__app__event-caption {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
}

.mpd-seating__app__event {
  flex-grow: 1;
}

.mpd-seating__app__event-title {
  font-weight: bold;
  font-size: 1.6rem;
}

.mpd-seating__app__event-date {
  margin-top: 5px;
}

@media (max-width: 881px) {
  .mpd-seating__app__event {
    padding: 4px 15px;
  }

  .mpd-seating__app__event-title {
    font-size: 1.4rem;
  }

  .mpd-seating__app__event-date {
    font-size: 1.2rem;
    margin-top: 0;
  }
}

.mpd-seating__app__container {
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  max-width: 100%;
}

.mpd-seating__app__event-svg-wrapper {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  overflow-y: hidden;
}

.mpd-seating__app--is-loading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mpd-seating__top-toolbar {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  z-index: 1;
}

.mpd-seating__top-toolbar__radio {
  width: 100%;

  .mpd-radio__children {
    padding: 10px 15px;
  }
}

.mpd-seating__bottom-toolbar {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mpd-seating__top-toolbar > * + *,
.mpd-seating__bottom-toolbar > * + * {
  margin-left: 15px;
}

@media screen and (max-width: $breakpoint-to-medium) {
  .mpd-seating__bottom-toolbar {
    flex-direction: column;
  }

  .mpd-seating__bottom-toolbar > * + * {
    margin-top: 15px;
  }
}
