@import '../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.mpd-seating__tooltip {
  display: inline-block;
  white-space: nowrap;
  padding: 10px 15px;
  border-radius: 8px;
  background-color: rgb(247, 245, 247, 0.9);
}

.mpd-seating__tooltip--is-desk {
  background-color: rgb(255, 255, 255, 0.9);
}

.seatId {
  display: none;
}

.context-config .seatId {
  display: initial;
}
