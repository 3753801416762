.replacement-sidebar__name {
  padding: 15px;
}

.replacement-sidebar__category-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
}

.replacement-sidebar__help {
  padding: 15px;
  margin: 0;
}

.replacement-sidebar__replacement-caption {
  padding: 15px;
  text-align: center;
}
